// export const hanaCardHost = "https://13.125.180.115:9142"
import {HmacSHA256} from "crypto-js";
import axios from "axios";

const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";
export const hanaCardHost = "https://hana-interface.com:9142"
// export const hanaCardHost = "https://web3pays.net/api/hana"
// export const hanaCardHost = proxy + "https://13.125.180.115:9142"

function unixTimeInMilliseconds() {
    return String(Date.now())
}

function unixTimeInSeconds() {
    return String(Math.floor(Date.now() / 1000))
}

function sendHanaServer(company, request, body) {
    const orderTime = unixTimeInMilliseconds()
    const param = orderTime + company.apiKey
    const sign = HmacSHA256(param, company.secretKey).toString()

    const headers = {
        "HANA-APIKEY": company.apiKey,
        "HANA-TS": orderTime,
        "HANA-SIGN": sign
    }

    body.owner_reg_no = company.ownerRegNo
    body.member_num = company.memberNum

    return axios.post(request, body, {
        headers: headers
    })
}

export function HCCardInfo(company, body) {
    const request = hanaCardHost + "/get_card_info"
    return sendHanaServer(company, request, body)
}

export function HCRegister(company, body) {
    const request = hanaCardHost + "/join_member"
    return sendHanaServer(company, request, body)
}

export function HCGetUserInfoCashSum(company, body) {
    const request = hanaCardHost + "/get_user_info_cash_sum"
    return sendHanaServer(company, request, body)
}

export function HCBalanceCardUserInfo(company, body) {
    const request = hanaCardHost + "/balance_card_user_info"
    return sendHanaServer(company, request, body)
}

export function HCTransactionHistory(company, body) {
    const request = hanaCardHost + "/get_transaction_history"
    return sendHanaServer(company, request, body)
}

export function HCDeposit(company, body) {
    const request = hanaCardHost + "/deposit_point"
    return sendHanaServer(company, request, body)
}

export function HCChangeLimit(company, body) {
    const request = hanaCardHost + "/change_limit"
    return sendHanaServer(company, request, body)
}

/* 카드정보 업데이트 */
export function HCUpdateMember(company, body) {
    const request = hanaCardHost + "/update_member"
    return sendHanaServer(company, request, body)
}

/* 분실신고 */
export function HCCardLock(company, body) {
    const request = hanaCardHost + "/card_lock"
    return sendHanaServer(company, request, body)
}

/* 외부출금 신청 */
export function HCWithdrawalPoint(company, body) {
    const request = hanaCardHost + "/withdrawal_point"
    return sendHanaServer(company, request, body)
}