import React, {Suspense} from "react";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from './redux/store'
import './App.css';
import SplashView from "./views/splash/SplashView";
import SignUp01 from "./views/user/SignUp01";
import CardRegister from "./views/card/CardRegister";
import CardHistory from "./views/card/CardHistory";
import CardCharge from "./views/card/CardCharge";
import CardTransfer from "./views/card/CardTransfer";
import CardStatus from "./views/card/CardStatus";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Suspense>
                    <Routes>
                        <Route path={"/"} name={"Splash"} element={<SplashView />} />
                        <Route path={"/signup"} name={"SignUp"} element={<SignUp01 />} />
                        {/*<Route path={"/home"} name={"Home"} element={<Home />} />*/}
                        <Route path={"/home"} name={"CardStatus"} element={<CardStatus />} />
                        {/*<Route path={"/send"} name={"Send"} element={<PointSendStep01 />} />*/}
                        <Route path={"/send"} name={"CardCharge"} element={<CardCharge />} />
                        {/*<Route path={"/history"} name={"History"} element={<PointHistory />} />*/}
                        <Route path={"/history"} name={"CardTransfer"} element={<CardTransfer />} />
                        {/*<Route path={"/pay"} name={"Pay"} element={<PayScreen />} />*/}
                        <Route path={"/pay"} name={"CardHistory"} element={<CardHistory />} />
                        <Route path={"/card/register"} name={"CardRegister"} element={<CardRegister />} />
                        <Route path={"/card/status"} name={"CardStatus"} element={<CardStatus />} />
                        <Route path={"/card/history"} name={"CardHistory"} element={<CardHistory />} />
                        <Route path={"/card/charge"} name={"CardCharge"} element={<CardCharge />} />
                        <Route path={"/card/transfer"} name={"CardTransfer"} element={<CardTransfer />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </Provider>
    )
}

export default App