// css
const modalWrapStyle = {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    position: 'fixed',
    top: 0, left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}

const modalStyle = {
    padding: 20,
}

// 어떠한 작업도 하지 않고 모달을 보여주는 역할만 함
export default function Modal({ onClose, children }) {
    return (
        <div
            className="modal"
            style={{...modalWrapStyle}}
            onClick={onClose}
        >
            <div
                className="modal_container"
                style={{...modalStyle}}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    )
}