import logo_black from '../assets/svg/logo_black.svg'
import icon_user from '../assets/svg/icon_user.svg'
import icon_prop from '../assets/svg/icon_prop.svg'

const GlobalNavigation = (props) => {
    return (
        <div className={"flex flex-row p-5 items-center justify-between"}>
            <div>
                <img src={logo_black} alt={""}/>
            </div>
            <div className={"flex flex-row gap-7"}>
                <img src={icon_user} alt={""}/>
                <img src={icon_prop} alt={""}/>
            </div>
        </div>
    )
}

export default GlobalNavigation