import axios from 'axios'
export const host = "https://api.innopay.co.kr/api"

// const mid = "testpay01m" // real
// const mid = "testpay02m"    // test
const mid = "pgyeongj1m"  // yj
// const licenseKey = "Ma29gyAFhvv/+e4/AHpV6pISQIvSKziLIbrNoXPbRS5nfTx2DOs8OJve+NzwyoaQ8p9Uy1AN4S1I0Um5v7oNUg=="
// const licenseKey = "7ssh+gyiyKfcTcaf47dwnWMqs+XweygK3eGrGAQH6au7LgRNnMQLq7sAiwp82xz8nFyBl4BK+ppwy4ECQkODzA=="
const licenseKey = "MkNlZJr/AWbHzimCm0q7xTSgXEc2s8x9i5Hme4vqOmLshv/GQ/aJVYIvQtmiJ2hNM/37reBPh0tmuDIxgYuNqQ=="

function post(request, body) {
    return axios.post(request, body);
}

export function vaccInquery(params) {
    const body = {
        mid: mid,
        licenseKey: licenseKey,
        moid: params.cardNo,                    // 요청번호
        reqCd: "S",                             // 요청구분 (S: 상태조회, D: 삭제)
        vbankBankCode: params.bankCode,         // 은행코드
        vbankNum: params.account,               // 계좌번호
        vbankAccountName: params.accountOwner,  // 입금자명
        countryCode: "KR",                      // 국가코드
        socNo: params.birth                     // 생년월일
    }

    const request = host + "/vaccInquery"
    return post(request, body)
}

export function vbankApi(params) {
    const body = {
        mid: mid,
        licenseKey: licenseKey,
        moid: params.cardNo,                    // 요청번호
        goodsCnt: 1,
        goodsName: params.goodsName,            // 상품명
        amt: params.amt,                        // 결제금액
        buyerName: params.accountOwner,            // 구매자명
        vbankBankCode: params.bankCode,         // 은행코드
        vbankNum: "",              // 가상계좌번호
        vbankAccountName: params.accountOwner,  // 입금자명
        countryCode: "KR",
        socNo: params.birth
    }

    const request = host + "/vbankApi"
    return post(request, body)
}