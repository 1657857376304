import React from 'react'
import icon_logo from '../assets/svg/logo.svg'

export const Loading = (props) => {
    return (
        <div className={"gap-2"}
             style={{
                 position: 'absolute',
                 background: '#000000b7',
                 zIndex: 999,
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 justifyContent: 'center',
                 top: 0,
                 left: 0,
                 width: '100vw',
                 height: '100vh'
             }}>
            <img className={"w-14 h-14 animate-spin"} src={icon_logo} alt={""}/>
        </div>
    )
}