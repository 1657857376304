import GlobalNavigation from "../GlobalNavigation";
import GlobalBottom from "../GlobalBottom";

const Frame = ({children}) => {
    return (
        <div className={"flex flex-col justify-center items-center bg-gradient-to-b from-[#FF8B57] to-[#E94738] overflow-y-scroll"}>
            <div className={"flex flex-col w-[390px] h-screen bg-[#F9F9F9] overflow-x-auto"}>
                <GlobalNavigation />
                <div className={"flex-glow overflow-y-scroll"}>
                    {children}
                </div>
                <GlobalBottom />
            </div>
        </div>
    )
}

export default Frame