import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import * as hanaApi from "../../api/hanaApi"
import * as api from "../../api/api";
import * as innoApi from "../../api/innoApi"
import useModal from "../../hooks/useModal";
import Alert from "../../common/Alert";
import numeral from "numeral";
import * as Global from '../../common/Global'

const CardCharge = (props) => {
    const {user} = useSelector((props) => props.account);

    const [loading, setLoading] = useState(false)
    const [userCard, setUserCard] = useState({})
    const [cardInfo, setCardInfo] = useState({
        chargeDiv: "V", chargePoint: "Cash", accountOwner: "", amount: "", bankCode: ""
    })
    const [bankCodeList, setBankCodeList] = useState([])
    const [alert, setAlert] = useState({
        visible: false, message: ""
    })

    const {Modal, open, close} = useModal();

    useEffect(() => {
        search()
        getBankCode()
    }, [])

    const search = () => {
        const params = {
            cmpId: Global.cmpId,
            cardNo: user.cardNo,
            isUse: 1
        }

        setLoading(true)
        api.getUserCard(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (Object.keys(data).length > 0) {
                    setUserCard(data)

                    getCashPoint()
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const [cashPoint, setCashPoint] = useState({})
    const getCashPoint = () => {
        const body = {
            card_num: user.cardNo
        }

        hanaApi.HCGetUserInfoCashSum(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length > 0) {
                    setCashPoint(data[0])
                }
                getBalancePoint()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const [balancePoint, setBalancePoint] = useState({})
    const getBalancePoint = () => {
        const body = {
            card_num: user.cardNo,
            tel_no : ""
        }

        hanaApi.HCBalanceCardUserInfo(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length > 0) {
                    setBalancePoint(data[0])
                }
                setLoading(false)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const getBankCode = () => {
        api.getBankCode().then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setBankCodeList(data)
                // 가져온 은행코드의 첫번째 값을 기본 설정
                setCardInfo((state) => ({
                    ...state,
                    bankCode: data[0].bankCode
                }))
            }
        })
            .catch(ex => window.alert(ex.message))
    }

    const request = () => {
        getDepositList()
    }

    const virtual = () => {
        // 가상계좌 발급 시작
        const body = {
            cmpId: Global.cmpId,
            cardNo: user.cardNo,
            goodsName: cardInfo.chargePoint === "Cash" ? "캐시포인트 충전" : "카드포인트 충전",            // 상품명
            amt: cardInfo.amount,                        // 결제금액
            buyerName: cardInfo.accountOwner,            // 구매자명
            accountOwner: cardInfo.accountOwner,  // 입금자명
            bankCode: cardInfo.bankCode,
            countryCode: "KR"
        }

        innoApi.vbankApi(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                putDeposit({...data, ...body})
            }
        })
    }

    const getDepositList = () => {
        const body = {
            cardNo: user.cardNo,
            status: "0"
        }

        if (window.confirm("충전을 요청하시겠어요?")) {
            if (cardInfo.chargeDiv === "V") {
                // 가상계좌
                virtual()
            }
            else if (cardInfo.chargeDiv === "D") {
                // 무통장입금
                deposit()
            }
        }

        // api.getDepositList(body).then(result => {
        //     const {data, status, statusText} = result
        //     if (status === 200) {
        //         if (data.length > 0) {
        //             window.alert("이전에 신청한 이력이 있습니다.")
        //             return
        //         }
        //         else {
        //
        //         }
        //     }
        // })
    }

    const deposit = () => {
        const body = {
            card_num: userCard.cardNo,
            input_kind: "무통장 입금",
            cash_amount: String(cardInfo.amount),
            state: "",
            cash_id: ""
        }

        if (cardInfo.chargePoint === 'Cash') {
            setLoading(true)
            hanaApi.HCDeposit(user.company, body).then(result => {
                const {data, status, statusText} = result
                if (status === 200) {
                    putDeposit(body)
                }
            })
                .catch(ex => {
                    window.alert(ex.message)
                    setLoading(false)
                })
        }
        else {
            putDeposit(body)
        }
    }

    const putDeposit = (body) => {
        const params = {
            cmpId: Global.cmpId,
            cardNo: user.cardNo,
            depositDiv: cardInfo.chargeDiv,
            pointDiv: cardInfo.chargePoint,
            amount: cardInfo.amount,
            type: "I",
            tid: cardInfo.chargeDiv === 'V' ? body.tid : '0',
            bankAccount: cardInfo.chargeDiv === 'V' ? body.vbankNum : '',
            bankNm: cardInfo.chargeDiv === 'V' ? body.vbankBankNm : '',
            bankOwner: cardInfo.chargeDiv === 'V' ? cardInfo.accountOwner : ''
        }
        console.log("### params :: ", params)

        api.putDeposit(params).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setAlert({
                    visible: true,
                    message: params.depositDiv === 'V' ?
                        "가상계좌가 정상적으로 발급되었습니다.\n\n입금은행 : " + body.vbankBankNm + "\n" +
                        "가상계좌번호 : " + body.vbankNum + "\n" +
                        "입금금액 : " + numeral(params.amount).format("0,0")
                        :
                        "요청이 관리자에게 접수되었습니다.\n관리자 승인까지는 시간이 소요될 수 있습니다."
                })
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>하나법인체크카드 충전</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col bg-[#505050] rounded-lg shadow-md px-4 py-2 gap-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-white text-lg font-bold"}>캐시포인트 잔액</span>
                        <span className={"text-white text-lg font-bold"}>{numeral(cashPoint.cash_amount).format("0,0")}</span>
                    </div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-white text-lg font-bold"}>카드포인트 잔액</span>
                        <span className={"text-white text-lg font-bold"}>{numeral(balancePoint.balance).format("0,0")}</span>
                    </div>
                </div>
                <div className={"flex flex-col bg-[#878787] rounded-lg shadow-md p-5 gap-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>충전수단</span>
                        <select className={"bg-transparent text-white text-xl font-bold"}
                                onChange={e => {
                                setCardInfo((state) => ({
                                    ...state,
                                    chargeDiv: e.target.value
                                }))
                        }}>
                            <option value={"V"}>가상계좌</option>
                            {/*<option value={"D"}>무통장입금</option>*/}
                        </select>
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>충전대상 포인트 선택</span>
                        <select className={"bg-transparent text-white text-xl font-bold"}
                                onChange={e => {
                                    setCardInfo((state) => ({
                                        ...state,
                                        chargePoint: e.target.value
                                    }))
                                }}>
                            <option value={"Cash"}>캐시포인트</option>
                            <option value={"Card"}>카드포인트</option>
                        </select>
                    </div>
                    {cardInfo.chargeDiv === 'V' &&
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>입금은행</span>
                        <select className={"bg-[#878787] text-white text-xl font-bold"}
                                onChange={e => {
                                    setCardInfo((state) => ({
                                        ...state,
                                        bankCode: e.target.value
                                    }))
                                }}>
                            {bankCodeList.map((bank, idx) => {
                                return <option key={String(idx)} value={bank.bankCode}>{bank.bankName}</option>
                            })}
                        </select>
                    </div>
                    }
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>입금자명</span>
                        <input className={"bg-transparent text-white font-bold text-xl"} placeholder={""}
                               onChange={e => {
                                   setCardInfo((state) => ({
                                       ...state, accountOwner: e.target.value
                                   }))
                               }}
                               value={cardInfo.accountOwner}/>
                    </div>
                    <div className={"flex flex-col gap-1"}>
                        <span className={"text-white font-bold"}>입금액</span>
                        <input className={"bg-transparent text-white font-bold text-lg"} placeholder={""}
                               type={"number"}
                               onChange={e => {
                                   setCardInfo((state) => ({
                                       ...state, amount: Number(e.target.value)
                                   }))
                               }}
                               value={cardInfo.amount}/>
                    </div>
                </div>
                <button className={"flex flex-col p-4 gap-2 rounded-lg bg-gradient-to-r from-[#717171] to-[#D9C7C6] items-center text-3xl text-white font-bold"}
                        onClick={request}>
                    충 전 요 청
                </button>
                <div className={"h-[80px]"} />
            </div>
            <Alert open={alert.visible} title={"Alert!"}
                   content={alert.message}
                   onClose={() => {
                       setAlert({
                           visible: false, message: ""
                       })
                   }} />
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardCharge)