import Frame from "../main/Frame";
import {Loading} from "../../common/Loading";
import icon_noti from "../../assets/svg/icon_noti.svg";
import {useEffect, useState} from "react";
import numeral from "numeral";
import dayjs from "dayjs";
import * as hanaApi from "../../api/hanaApi";
import {connect, useSelector} from "react-redux";
import * as api from "../../api/api";
import * as Global from "../../common/Global";

const CardHistory = (props) => {
    const {user} = useSelector((props) => props.account);

    const [userCard, setUserCard] = useState({})
    const [loading, setLoading] = useState(false)
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        const body = {
            cmpId: Global.cmpId,
            cardNo: user.cardNo,
            isUse: 1
        }

        setLoading(true)
        api.getUserCard(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (Object.keys(data).length > 0) {
                    setUserCard(data)
                    // getCardTransactionHistory(data.cardNo)
                    getUserCardHistory(data.cardNo)

                    getCashPoint()
                }
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getUserCardHistory = (cardNo) => {
        const body = {
            cardNo: cardNo
        }
        setLoading(true)
        api.getUserCardHistory(body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                // 최대 5건만 표시하자
                setDataList(data)
            }
        })
    }

    const getCardTransactionHistory = (cardNo) => {
        const body = {
            card_num: cardNo,
            from_site_date: dayjs().add(-30, "d").format("YYYYMMDD"),
            to_site_date: dayjs().add(-1, "d").format("YYYYMMDD"),
            state: ""
        }

        setLoading(true)
        hanaApi.HCTransactionHistory(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                setDataList(data)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const [cashPoint, setCashPoint] = useState({})
    const getCashPoint = () => {
        const body = {
            card_num: user.cardNo
        }

        hanaApi.HCGetUserInfoCashSum(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length > 0) {
                    setCashPoint(data[0])
                }
                getBalancePoint()
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    const [balancePoint, setBalancePoint] = useState({})
    const getBalancePoint = () => {
        const body = {
            card_num: user.cardNo,
            tel_no : ""
        }

        hanaApi.HCBalanceCardUserInfo(user.company, body).then(result => {
            const {data, status, statusText} = result
            if (status === 200) {
                if (data.length > 0) {
                    setBalancePoint(data[0])
                }
                setLoading(false)
            }
        })
            .catch(ex => {
                window.alert(ex.message)
            })
    }

    return (
        <Frame>
            {loading ? <Loading /> : null}
            <div className={"flex flex-col px-4 gap-2"}>
                <div className={"flex flex-row items-center justify-between"}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <img src={icon_noti} alt={""}/>
                        <span className={"text-[#454545]"}>하나법인체크카드 사용내역</span>
                    </div>
                    <span className={"text-[#454545] font-bold"}>{">"}</span>
                </div>
                <div className={"h-[30px]"}/>
                <div className={"flex flex-col bg-[#505050] rounded-lg shadow-md px-4 py-2 gap-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-white text-lg font-bold"}>캐시포인트 잔액</span>
                        <span className={"text-white text-lg font-bold"}>{numeral(cashPoint.cash_amount).format("0,0")}</span>
                    </div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"text-white text-lg font-bold"}>카드포인트 잔액</span>
                        <span className={"text-white text-lg font-bold"}>{numeral(balancePoint.balance).format("0,0")}</span>
                    </div>
                </div>
                <div className={"flex-grow flex-col bg-[#878787] rounded-lg shadow-md p-5"}>
                    <div className={"flex flex-row justify-between pb-2"}>
                        <span className={"w-[100px] text-white font-bold"}>사용처</span>
                        <span className={"text-white font-bold"}>사용일시</span>
                        <span className={"text-white font-bold"}>사용포인트</span>
                    </div>
                    <div className={"overflow-y-auto"}>
                        {dataList.map((item, idx) => {
                            return (
                                <div className={"flex flex-row border-b-2 border-gray-400 items-center justify-between py-2"}>
                                    {/*<span className={"w-[100px] text-sm text-white"}>{item.payType}</span>*/}
                                    {/*<span className={"text-sm text-white text-center"}>{item.instTime}</span>*/}
                                    {/*<span className={"text-sm text-white font-bold"}>{numeral(item.point).format("0,0")}</span>*/}
                                    <span className={"text-white text-center"}>{item.procDiv === 'O' ? "이체" : "충전"}</span>
                                    <span className={"text-white text-center"}>{item.procDt}</span>
                                    <span className={"text-white"}>{numeral(item.amount).format("0,0")}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={"h-[80px]"} />
            </div>
        </Frame>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(CardHistory)