import Frame from "../main/Frame";
import icon_noti from "../../assets/svg/icon_noti.svg"
import icon_uncheck from "../../assets/svg/icon_uncheck.svg"
import icon_checked from "../../assets/svg/icon_checked.svg"
import {useState} from "react";

const SignUp01 = (props) => {
    const [termCheck01, setTermCheck01] = useState(false)
    const [termCheck02, setTermCheck02] = useState(false)

    const toggleCheck01 = () => {
        setTermCheck01(!termCheck01)
    }

    const toggleCheck02 = () => {
        setTermCheck02(!termCheck02)
    }

    return (
        <Frame>
            <div className={"flex flex-col px-5 gap-2"}>
                <div className={"flex flex-row gap-2 items-center"}>
                    <img src={icon_noti} alt={""}/>
                    <span className={"text-[#454545]"}>회원가입</span>
                </div>
                <div className={"h-[30px]"} />
                <div className={"flex flex-row p-5 rounded-xl bg-gradient-to-r from-[#FF7340] to-[#F64332] justify-between"}>
                    <span className={"text-white font-bold"}>서비스 이용약관</span>
                    <div className={"flex flex-row gap-4 items-center"}>
                        <span className={"text-white font-bold"}>보기</span>
                        <img src={termCheck01 ? icon_checked : icon_uncheck} alt={""} onClick={toggleCheck01}/>
                    </div>
                </div>
                <div className={"flex flex-row p-5 rounded-xl bg-gradient-to-r from-[#FF7340] to-[#F64332] justify-between"}>
                    <span className={"text-white font-bold"}>전자금융거래 이용약관</span>
                    <div className={"flex flex-row gap-4 items-center"}>
                        <span className={"text-white font-bold"}>보기</span>
                        <img src={termCheck02 ? icon_checked : icon_uncheck} alt={""} onClick={toggleCheck02}/>
                    </div>
                </div>
            </div>
            <div className={"h-0.5 bg-gray-400 my-6"} />
            <div className={"flex flex-col px-5"}>
                <p>Web3pays는 가장 쉬운 로그인을 제공합니다.</p>
                <p>실제 포인트를 사용하기 전까지 어떠한 개인정보도 요구하지 않습니다.</p>
                <p>E-mail은 세상에서 가장 안전한 구글 로그인 하세요.</p>
                <p>:D</p>
            </div>
        </Frame>
    )
}

export default SignUp01